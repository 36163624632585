import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Discutons un peu !"
          subheader="Un message d'amour – ou de haine – que vous voulez nous transmettre ?
                    Une demande de collaboration ou une proposition ? N'hésitez pas ! On vous répondra rapidement."
          running= "Si vous voulez nous contacter par mail sans passer par le formulaire, vous pouvez envoyer un email à l'adresse contact@gamersden.fr"
        />
        <Divider />
        <ContactForm />
      </Main>
    </Stack>
  </Layout>
)

export default PageContact
